<template>
  <base-section id="k-d-a-careers-list" class="white" space="80" top-inner-shadow>
    <v-container class="pa-0 px-7">
      <v-row
        align="center"
        justify="center"
        :style="{ gap: g_bLowerBr ? '32px' : '64px' }"
        no-gutters
      >
        <v-col v-if="!g_bLowerBr" cols="12">
          <h1 class="kda-ts-46pt wt-extrabold">{{ m_strTitle }}</h1>
        </v-col>

        <v-col cols="12">
          <v-row align="start">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="query"
                outlined
                label="Search"
                prepend-inner-icon="mdi-magnify"
                class="pt-1 kda-ts-16pt nunito"
                clearable
                hide-details
                :height="g_bLowestBr ? 44 : 'auto'"
                @click:clear="clearQuery"
              ></v-text-field>
            </v-col>

            <v-col v-if="m_arrQueries.length > 0" cols="12" md="6">
              <v-sheet>
                <v-chip-group show-arrows>
                  <v-chip
                    v-for="(tag, i) in m_arrQueries"
                    :key="tag + '-' + i"
                    :class="`my-0 mx-2 kda-ts-16pt nunito wt-bold ${i === 0 && 'ml-auto'}`"
                    label
                    close
                    color="main-r-450"
                    text-color="white"
                    :large="!g_bLowerBr"
                    @click:close="() => {}"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>

        <template v-if="m_arrVacancies.length > 0">
          <v-col cols="12">
            <v-row :no-gutters="g_bLowerBr" :style="{ rowGap: g_bLowerBr ? '24px' : '48px' }">
              <v-col
                v-for="(vacancy, i) in m_arrShownVacancies"
                :key="`list-of-vacancy-${i}`"
                cols="12"
                md="6"
              >
                <k-d-a-career-card
                  :str-vacancy-title="vacancy.strTitle"
                  :str-vacancy-description="vacancy.strDescription"
                  :t-date-posted="vacancy.tDatePosted"
                  :t-date-closing="vacancy.tDateClosing"
                  :str-label="vacancy.strLabel"
                  :hex-label-color="vacancy.hexLabelColor"
                  @apply="
                    $router.push({
                      name: 'KDACareerApplication',
                      params: { strHashedID: vacancy.strID ?? 'dummy' }
                    })
                  "
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div :class="`d-flex justify-${g_bLowerBr ? 'center' : 'end'}`">
              <v-pagination
                v-model="page"
                :length="Math.ceil(m_arrFilteredVacancies.length / m_iShownLimit)"
                :total-visible="g_bLowerBr ? 4 : 10"
                class="kda-ts-16pt nunito"
                color="main-r-450"
                @input="onPaginationClick"
              ></v-pagination>
            </div>
          </v-col>
        </template>

        <p v-else class="kda-ts-16pt">
          {{ m_strNoVacancy }}
        </p>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import KDAVacancyList from './extension/KDAVacancyList';

export default {
  name: 'KDACareersList',

  components: {
    KDACareerCard: () => import('@/components/KDACareerCard')
  },

  extends: KDAVacancyList,

  mixins: [BaseBreakpoint],

  data() {
    return {
      page: 1,
      query: ''
    };
  },

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.opening.title');
    },
    m_arrQueries() {
      // TODO: add handler for searched queries
      // return ['Engineer', 'Designer', 'Sales', 'Business'];
      return [];
    },
    m_arrFilteredVacancies() {
      return this.m_arrVacancies.filter((vacancy) =>
        vacancy.strTitle.toLowerCase().includes(this.$data.query.toLowerCase())
      );
    },
    m_iShownLimit() {
      return this.g_bLowerBr ? 2 : 6;
    },
    m_arrShownVacancies() {
      const start = (this.page - 1) * this.m_iShownLimit;
      return this.m_arrFilteredVacancies.slice(start, start + this.m_iShownLimit);
    }
  },

  methods: {
    onPaginationClick(_) {
      document.getElementById('k-d-a-careers-list').scrollIntoView({
        behavior: 'smooth'
      });
    },
    clearQuery() {
      this.$data.query = '';
    }
  }
};
</script>
